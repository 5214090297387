import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import CustomAlert from '../common/alert/custom';
import Cookies from 'js-cookie';
import axios from 'axios';

class UpdatePetFinder extends Component {
  constructor(props) {
    super(props);
    if (Cookies.get('id') === undefined) {
      Cookies.set('redirect', true);
      Cookies.set("path", "update-pet-finder");
      this.props.history.push('/login');
    }
    const { match } = this.props;
    const { params } = match;
    const { tag_id } = params;
    const data = {
      "tag_id": tag_id
    };

    this.state = {
      tag: {},
      tag_id: tag_id,
      contact_id: Cookies.get('id'),
      formData: {
        dob: "",
        pet_name: "",
        pet_type: "",
        gender: "",
        image: null,
        breed: "",
        weight: "",
        color: "",
        behaviour: [],
        health: [],
        diseases: [],
        food: [],
        owners: [{
          owner_name: "",
          contact_no: "",
          whatsapp_no: "",
          email: "",
          address: {
            show: true,
            area: "",
            city: "",
            state: "",
            country: "India",  // Default to India
            pincode: ""
          },
          show_contact: true,
          show_whatsapp: true,
          show_email: false
        }],
        alert_on: false,
      },
      isPreviewVisible: true,
      states: [
        "Andhra Pradesh", "Andaman and Nicobar Islands", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Chandigarh", "Delhi", "Dadra and Nagar Haveli", "Daman and Diu", "Goa", "Gujarat",
        "Haryana", "Himachal Pradesh", "Jharkhand", "Jammu and Kashmir", "Karnataka", "Kerala", "Ladakh", "Lakshadweep", "Madhya Pradesh",
        "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Other", "Puducherry", "Punjab", "Rajasthan",
        "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal",
      ],
      petBreeds: {
        dog: [
          "Other", "Alaskan Malamute", "Bernese Mountain Dog", "Boxer", "Doberman Pinscher", "Great Dane", "Mastiff",
          "Rottweiler", "Saint Bernard", "Siberian Husky", "Labrador Retriever", "Golden Retriever", "Cocker Spaniel",
          "English Springer Spaniel", "Weimaraner", "Brittany Spaniel", "Irish Setter", "Newfoundland",
          "Australian Shepherd", "Border Collie", "Collie", "German Shepherd", "Shetland Sheepdog",
          "Pembroke Welsh Corgi", "Old English Sheepdog", "Beagle", "Basset Hound", "Bloodhound", "Greyhound", "Whippet",
          "Chihuahua", "Pomeranian", "Shih Tzu", "Pekingese", "Yorkshire Terrier", "Maltese", "Papillon", "Toy Poodle",
          "Bulldog", "Dalmatian", "Poodle", "French Bulldog", "Shiba Inu", "Bichon Frisé", "Boston Terrier",
          "Bull Terrier", "Jack Russell Terrier", "Scottish Terrier", "West Highland White Terrier (Westie)", "Cairn Terrier",
          "Airedale Terrier", "Schnauzer", "Dogo Argentino", "Belgian Laekenois", "Portuguese Water Dog", "American Bully",
          "Australian Cattle Dog", "Basenji", "Chinese Crested", "Rhodesian Ridgeback"
        ],
        cat: [
          "Persian", "Maine Coon", "Siamese", "Bengal", "Sphynx", "Ragdoll", "Abyssinian", "British Shorthair",
          "Scottish Fold", "Russian Blue", "Exotic Shorthair", "Burmese", "Birman", "Oriental Shorthair", "Manx"
        ]
      },
      suggestedBehaviours: [
        "Friendly", "Aggressive", "Playful", "Calm", "Energetic", "Curious", "Loyal", "Protective", "Shy", "Independent"
      ],
      suggestedFoods: [
        // Dog foods
        "Home Food", "Veg", "Non-veg",
        "Pedigree",
        "Royal Canin",
        "Hill's Science Diet",
        "Blue Buffalo",
        "Purina Pro Plan",
        "Iams",
        "Orijen",
        "Acana",
        "Wellness Core",
        "Nutro",
        "Whiskas",
        "Merrick",

      ],
      suggestedHealths: [
        "Good Health",
        "Joint Issues",
        "Skin Issues",
        "Digestive Issues",
        "Respiratory Issues",
        "Other Issues"
      ],
      suggestedDiseases: ["Arthritis", "Hip Dysplasia", "Elbow Dysplasia",
        "Allergies", "Hot Spots", "Fleas", "Fungal Infections", "IBD", "Gastroenteritis", "Kennel Cough", "Pneumonia", "Bronchitis", "Asthma", "Feline Herpesvirus", "Pneumonia", "Obesity", "Metabolic Syndrome"
      ],
      showAlert: false,
    }
    this.fileInputRef = React.createRef();

    this.getTags(data.tag_id);
  }

  // Fetch the tag data
  getTags = async (tag_id) => {
    const response = await axios.get(process.env.REACT_APP_API_URL_NEW + '/tags/' + tag_id, {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });
    if (response.data.status == true) {
      if ((response.data.tags !== null)) {
        this.setState({
          tag: response.data.tag,
        }, () => {
          this.setFormData(response.data.tag);
        });
      }
    }
  };

  // Set form data after fetching tag
  setFormData = (tag) => {
    const dob = this.formatDateForInput(tag.dob);
    this.setState({
      formData: {
        email: tag.email || "",
        dob: dob || "",
        pet_name: tag.pet_name || "",
        pet_type: tag.pet_type || "",
        gender: tag.gender || "",
        image: tag.image || null,
        breed: tag.breed || "",
        weight: tag.weight || "",
        color: tag.colour || "",
        behaviour: tag.behaviour || [],
        health: tag.health || [],
        diseases: tag.diseases || [],
        food: tag.food || [],
        owners: tag.owners || [],
        alert_on: tag.alert_on || false,
      },
      tempImage: tag.image || null,
    });
  };

  // Format date for the input field
  formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Handle form input changes
  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: type === "checkbox" ? checked : value,
      }
    }));
  };



  // Handle form input changes for all form fields, including owners
  handleChange = (e, ownerIndex = null) => {
    const { name, value, type, checked } = e.target;

    if (name === 'pet_type') {
      // If pet type is selected, update the breed list
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          pet_type: value,
          breed: "", // Reset breed when pet type changes
        }
      }));
    } else if (ownerIndex !== null) {
      // Handle owner fields
      if (name in this.state.formData.owners[ownerIndex].address) {
        this.setState(prevState => {
          const updatedOwners = [...prevState.formData.owners];
          updatedOwners[ownerIndex].address[name] = value;
          return {
            formData: {
              ...prevState.formData,
              owners: updatedOwners,
            }
          };
        });
      } else {
        this.setState(prevState => {
          const updatedOwners = [...prevState.formData.owners];
          updatedOwners[ownerIndex][name] = type === "checkbox" ? checked : value;
          return {
            formData: {
              ...prevState.formData,
              owners: updatedOwners,
            }
          };
        });
      }
    } else {
      // Handle other fields
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: type === "checkbox" ? checked : value,
        }
      }));
    }
  };

  // Handle file input change for image upload
  handleFileChange = (e) => {
    const { name, files } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: files[0] },
      isPreviewVisible: true,
    }));
  };

  // Add a new owner input
  addOwner = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        owners: [...prevState.formData.owners, {
          owner_name: "",
          contact_no: "",
          whatsapp_no: "",
          email: "",
          address: {
            show: true,
            area: "",
            city: "",
            state: "",
            country: "India",  // Default to India
            pincode: ""
          },
          show_contact: true,
          show_whatsapp: true,
          show_email: false
        }]
      }
    }));
  };

  // Remove an owner input
  removeOwner = (index) => {
    this.setState(prevState => {
      const updatedOwners = prevState.formData.owners.filter((_, i) => i !== index);
      return {
        formData: {
          ...prevState.formData,
          owners: updatedOwners,
        }
      };
    });
  };

  handleBehaviourSelect = (behaviour) => {
    this.setState(prevState => {
      const updatedBehaviour = [...prevState.formData.behaviour, behaviour];
      return {
        formData: {
          ...prevState.formData,
          behaviour: updatedBehaviour,
        }
      };
    });

    // Hide the selected behaviour suggestion button

    // document.getElementById('selectedBehavioursDiv').innerHTML = 'Selected: ' + prevState.formData.behaviour.join(', ');
  };

  componentDidUpdate(prevProps, prevState) {
    // If behaviour list changed, update the "Selected" div and suggestions
    if (prevState.formData.behaviour !== this.state.formData.behaviour) {
      // Update the selected behaviours display
      const selectedBehavioursDiv = document.getElementById('selectedBehavioursDiv');
      selectedBehavioursDiv.innerHTML = '';
      selectedBehavioursDiv.style.border = '1px solid #ccc';
      selectedBehavioursDiv.style.borderRadius = '5px';
      selectedBehavioursDiv.style.padding = '5px';

      // Add each selected behaviour with a remove button
      this.state.formData.behaviour.forEach(behaviour => {
        const behaviourDiv = document.createElement('div');
        behaviourDiv.style.display = 'inline-block';
        behaviourDiv.style.margin = '5px';
        behaviourDiv.style.paddingLeft = '5px';
        behaviourDiv.style.border = '1px solid #ccc';
        behaviourDiv.style.borderRadius = '5px';

        const behaviourText = document.createElement('span');
        behaviourText.textContent = behaviour;

        // Create remove button
        const removeButton = document.createElement('button');
        removeButton.textContent = 'X';
        removeButton.classList.add('btn', 'btn-danger', 'btn-sm');
        removeButton.style.marginLeft = '5px';
        removeButton.style.borderRadius = '5px';
        removeButton.onclick = () => this.removeBehaviour(behaviour); // On click, remove behaviour

        // Append text and remove button to the div
        behaviourDiv.appendChild(behaviourText);
        behaviourDiv.appendChild(removeButton);

        // Append the behaviour div to the selected behaviours div
        selectedBehavioursDiv.appendChild(behaviourDiv);
      });

      // Hide suggestion buttons for selected behaviours and show suggestion for removed ones
      this.state.formData.behaviour.forEach(behaviour => {
        const suggestionElement = document.getElementById(`suggestion-${behaviour}`);
        if (suggestionElement) {
          suggestionElement.style.display = 'none'; // Hide the selected suggestion
        }
      });

      // Show suggestion buttons for remaining behaviours
      this.state.suggestedBehaviours.forEach(behaviour => {
        if (!this.state.formData.behaviour.includes(behaviour)) {
          const suggestionElement = document.getElementById(`suggestion-${behaviour}`);
          if (suggestionElement) {
            suggestionElement.style.display = 'inline-block'; // Show the available suggestion
          }
        }
      });
    }
    // If food list changed, update the "Selected" div and suggestions
    if (prevState.formData.food !== this.state.formData.food) {
      // Update the selected foods display
      const selectedFoodDiv = document.getElementById('selectedFoodDiv');
      selectedFoodDiv.innerHTML = '';
      selectedFoodDiv.style.border = '1px solid #ccc';
      selectedFoodDiv.style.borderRadius = '5px';
      selectedFoodDiv.style.padding = '5px';

      // Add each selected food with a remove button
      this.state.formData.food.forEach(food => {
        const foodDiv = document.createElement('div');
        foodDiv.style.display = 'inline-block';
        foodDiv.style.margin = '5px';
        foodDiv.style.paddingLeft = '5px';
        foodDiv.style.border = '1px solid #ccc';
        foodDiv.style.borderRadius = '5px';

        const foodText = document.createElement('span');
        foodText.textContent = food;

        // Create remove button
        const removeButton = document.createElement('button');
        removeButton.textContent = 'X';
        removeButton.classList.add('btn', 'btn-danger', 'btn-sm');
        removeButton.style.marginLeft = '5px';
        removeButton.style.borderRadius = '5px';
        removeButton.onclick = () => this.removeFood(food); // On click, remove food

        // Append text and remove button to the div
        foodDiv.appendChild(foodText);
        foodDiv.appendChild(removeButton);

        // Append the food div to the selected foods div
        selectedFoodDiv.appendChild(foodDiv);
      });

      // Hide suggestion buttons for selected foods and show suggestion for removed ones
      this.state.formData.food.forEach(food => {
        const suggestionElement = document.getElementById(`suggestion-${food}`);
        if (suggestionElement) {
          suggestionElement.style.display = 'none'; // Hide the selected suggestion
        }
      });

      // Show suggestion buttons for remaining foods
      this.state.suggestedFoods.forEach(food => {
        if (!this.state.formData.food.includes(food)) {
          const suggestionElement = document.getElementById(`suggestion-${food}`);
          if (suggestionElement) {
            suggestionElement.style.display = 'inline-block'; // Show the available suggestion
          }
        }
      });
    }
    if (prevState.formData.health !== this.state.formData.health) {
      const selectedHealthDiv = document.getElementById('selectedHealthDiv');
      selectedHealthDiv.innerHTML = ''; // Clear current health items

      if (this.state.formData.health.includes("Good Health") && this.state.formData.health.length == 1) {
        const selectedDiseasesDiv = document.getElementById('selectedDiseasesDiv');
        selectedDiseasesDiv.innerHTML = ''; // Clear current diseases
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            diseases: [],
          }
        }));
      }

      // Add each selected health item with a remove button
      this.state.formData.health.forEach(health => {
        const healthDiv = document.createElement('div');
        healthDiv.style.display = 'inline-block';
        healthDiv.style.margin = '5px';
        healthDiv.style.paddingLeft = '5px';
        healthDiv.style.border = '1px solid #ccc';
        healthDiv.style.borderRadius = '5px';

        const healthText = document.createElement('span');
        healthText.textContent = health;

        // Create remove button
        const removeButton = document.createElement('button');
        removeButton.textContent = 'X';
        removeButton.classList.add('btn', 'btn-danger', 'btn-sm');
        removeButton.style.marginLeft = '5px';
        removeButton.style.borderRadius = '5px';
        removeButton.onclick = () => this.removeHealth(health);

        // Append text and remove button to the div
        healthDiv.appendChild(healthText);
        healthDiv.appendChild(removeButton);

        // Append the health div to the selected health div
        selectedHealthDiv.appendChild(healthDiv);
      });

      // Hide suggestion buttons for selected health items and show suggestion for removed ones
      this.state.formData.health.forEach(health => {
        const suggestionElement = document.getElementById(`suggestion-${health}`);
        if (suggestionElement) {
          suggestionElement.style.display = 'none'; // Hide the selected suggestion
        }
      });

      // Show suggestion buttons for remaining health items
      this.state.suggestedHealths.forEach(health => {
        if (!this.state.formData.health.includes(health)) {
          const suggestionElement = document.getElementById(`suggestion-${health}`);
          if (suggestionElement) {
            suggestionElement.style.display = 'inline-block'; // Show the available suggestion
          }
        }
      });
    }
    if (prevState.formData.diseases !== this.state.formData.diseases) {
      const selectedDiseasesDiv = document.getElementById('selectedDiseasesDiv');
      selectedDiseasesDiv.innerHTML = ''; // Clear current diseases

      // Add each selected disease with a remove button
      this.state.formData.diseases.forEach(disease => {
        const diseaseDiv = document.createElement('div');
        diseaseDiv.style.display = 'inline-block';
        diseaseDiv.style.margin = '5px';
        diseaseDiv.style.paddingLeft = '5px';
        diseaseDiv.style.border = '1px solid #ccc';
        diseaseDiv.style.borderRadius = '5px';

        const diseaseText = document.createElement('span');
        diseaseText.textContent = disease;

        // Create remove button
        const removeButton = document.createElement('button');
        removeButton.textContent = 'X';
        removeButton.classList.add('btn', 'btn-danger', 'btn-sm');
        removeButton.style.marginLeft = '5px';
        removeButton.style.borderRadius = '5px';
        removeButton.onclick = () => this.removeDisease(disease);

        // Append text and remove button to the div
        diseaseDiv.appendChild(diseaseText);
        diseaseDiv.appendChild(removeButton);

        // Append the disease div to the selected diseases div
        selectedDiseasesDiv.appendChild(diseaseDiv);
      });

      // Hide suggestion buttons for selected diseases and show suggestion for removed ones
      this.state.formData.diseases.forEach(disease => {
        const suggestionElement = document.getElementById(`suggestion-${disease}`);
        if (suggestionElement) {
          suggestionElement.style.display = 'none'; // Hide the selected suggestion
        }
      });

      // Show suggestion buttons for remaining diseases
      this.state.suggestedDiseases.forEach(disease => {
        if (!this.state.formData.diseases.includes(disease)) {
          const suggestionElement = document.getElementById(`suggestion-${disease}`);
          if (suggestionElement) {
            suggestionElement.style.display = 'inline-block'; // Show the available suggestion
          }
        }
      });
    }
  }

  // Remove behaviour from the selected list and add it back to suggestions
  removeBehaviour = (behaviourToRemove) => {
    this.setState(prevState => {
      const updatedBehaviourList = prevState.formData.behaviour.filter(behaviour => behaviour !== behaviourToRemove);

      // Add removed behaviour back to suggestions if it's not already there
      const updatedSuggestions = [...prevState.suggestedBehaviours];
      if (!updatedSuggestions.includes(behaviourToRemove)) {
        updatedSuggestions.push(behaviourToRemove);
      }

      return {
        formData: {
          ...prevState.formData,
          behaviour: updatedBehaviourList,
        },
        suggestedBehaviours: updatedSuggestions,
      };
    });
  };

  // Handle suggestion click event (select behaviour and move to selected)
  suggestionBehaviourClick = (suggestionId) => {
    const selectedBehaviour = suggestionId.replace('suggestion-', ''); // Get behaviour name from suggestion id

    this.setState(prevState => {
      if (!prevState.formData.behaviour.includes(selectedBehaviour)) {
        // Add behaviour to selected list
        return {
          formData: {
            ...prevState.formData,
            behaviour: [...prevState.formData.behaviour, selectedBehaviour],
          },
          suggestedBehaviours: prevState.suggestedBehaviours.filter(behaviour => behaviour !== selectedBehaviour), // Remove from suggestions
        };
      }
    });
  };
  // food
  handleFoodSelect = (food) => {
    this.setState(prevState => {
      const updatedFood = [...prevState.formData.food, food];
      return {
        formData: {
          ...prevState.formData,
          food: updatedFood,
        }
      };
    });

    // Hide the selected food suggestion button
  };

  // Remove food from the selected list and add it back to suggestions
  removeFood = (foodToRemove) => {
    this.setState(prevState => {
      const updatedFoodList = prevState.formData.food.filter(food => food !== foodToRemove);

      // Add removed food back to suggestions if it's not already there
      const updatedSuggestions = [...prevState.suggestedFoods];
      if (!updatedSuggestions.includes(foodToRemove)) {
        updatedSuggestions.push(foodToRemove);
      }

      return {
        formData: {
          ...prevState.formData,
          food: updatedFoodList,
        },
        suggestedFoods: updatedSuggestions,
      };
    });
  };

  // Handle suggestion click event (select food and move to selected)
  suggestionFoodClick = (suggestionId) => {
    const selectedFood = suggestionId.replace('suggestion-', ''); // Get food name from suggestion id

    this.setState(prevState => {
      if (!prevState.formData.food.includes(selectedFood)) {
        // Add food to selected list
        return {
          formData: {
            ...prevState.formData,
            food: [...prevState.formData.food, selectedFood],
          },
          suggestedFoods: prevState.suggestedFoods.filter(food => food !== selectedFood), // Remove from suggestions
        };
      }
    });
  };

  // Method to handle health selection
  handleHealthSelect = (health) => {
    this.setState(prevState => {
      if (health === "Good Health") {
        const updatedHealth = [...prevState.formData.health, health];
        return {
          formData: {
            ...prevState.formData,
            health: updatedHealth,
            diseases: [], // Clear diseases if "Good Health" is selected
          }
        };
      }
      const updatedHealth = [...prevState.formData.health, health];
      return {
        formData: {
          ...prevState.formData,
          health: updatedHealth,
        }
      };
    });

    // Hide the selected health suggestion button
    // document.getElementById('selectedHealthDiv').innerHTML = 'Selected: ' + prevState.formData.health.join(', ');
  };

  // Method to remove health item and add back to suggestions
  removeHealth = (healthToRemove) => {
    this.setState(prevState => {
      const updatedHealthList = prevState.formData.health.filter(health => health !== healthToRemove);

      // Add removed health item back to suggestions if it's not already there
      const updatedSuggestions = [...prevState.suggestedHealths];
      if (!updatedSuggestions.includes(healthToRemove)) {
        updatedSuggestions.push(healthToRemove);
      }

      return {
        formData: {
          ...prevState.formData,
          health: updatedHealthList,
        },
        suggestedHealths: updatedSuggestions,
      };
    });
  };

  // Method to handle the suggestion click event (select health and move to selected)
  suggestionHealthClick = (suggestionId) => {
    const selectedHealth = suggestionId.replace('suggestion-', ''); // Get health name from suggestion id

    this.setState(prevState => {
      if (!prevState.formData.health.includes(selectedHealth)) {
        return {
          formData: {
            ...prevState.formData,
            health: [...prevState.formData.health, selectedHealth], // Add health to selected list
          },
          suggestedHealths: prevState.suggestedHealths.filter(health => health !== selectedHealth), // Remove from suggestions
        };
      }
    });
  };

  // Method to handle disease selection
  handleDiseaseSelect = (disease) => {
    this.setState(prevState => {
      const updatedDiseases = [...prevState.formData.diseases, disease];
      return {
        formData: {
          ...prevState.formData,
          diseases: updatedDiseases,
        }
      };
    });

    // Hide the selected disease suggestion button
    // document.getElementById('selectedDiseasesDiv').innerHTML = 'Selected: ' + prevState.formData.diseases.join(', ');
  };

  // Method to remove disease from the selected list and add back to suggestions
  removeDisease = (diseaseToRemove) => {
    this.setState(prevState => {
      const updatedDiseaseList = prevState.formData.diseases.filter(disease => disease !== diseaseToRemove);

      // Add removed disease back to suggestions if it's not already there
      const updatedSuggestions = [...prevState.suggestedDiseases];
      if (!updatedSuggestions.includes(diseaseToRemove)) {
        updatedSuggestions.push(diseaseToRemove);
      }

      return {
        formData: {
          ...prevState.formData,
          diseases: updatedDiseaseList,
        },
        suggestedDiseases: updatedSuggestions,
      };
    });
  };

  // Method to handle the suggestion click event (select disease and move to selected)
  suggestionDiseaseClick = (suggestionId) => {
    const selectedDisease = suggestionId.replace('suggestion-', ''); // Get disease name from suggestion id

    this.setState(prevState => {
      if (!prevState.formData.diseases.includes(selectedDisease)) {
        return {
          formData: {
            ...prevState.formData,
            diseases: [...prevState.formData.diseases, selectedDisease], // Add disease to selected list
          },
          suggestedDiseases: prevState.suggestedDiseases.filter(disease => disease !== selectedDisease), // Remove from suggestions
        };
      }
    });
  };

  // Toggle preview visibility and image clearing
  togglePreview = (e) => {
    e.preventDefault();
    if (this.state.isPreviewVisible) {
      this.clearImage(); // Clear image if preview is currently visible
    } else {
      this.setState({ isPreviewVisible: true }); // Show preview if it's not already visible
    }
  };

  // Clear the image and reset the preview visibility while preserving other form data
  clearImage = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = ''; // Reset file input field
    }
    this.setState(prevState => ({
      formData: {
        ...prevState.formData, // Spread the existing form data
        image: null, // Remove the selected image
      },
      isPreviewVisible: false, // Hide the preview
    }));
  };


  handleHideAlert = () => {
    this.setState({ showAlert: false });
  };
  handleShowAlert = () => {
    this.setState({ showAlert: true });
  };

  // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData } = this.state;

    // Create a new FormData object
    const updatedFormData = new FormData();

    // Append basic fields to the FormData object
    updatedFormData.append('tag_id', this.state.tag_id);
    updatedFormData.append('contact_id', this.state.contact_id);
    updatedFormData.append('dob', formData.dob);
    updatedFormData.append('pet_name', formData.pet_name);
    updatedFormData.append('pet_type', formData.pet_type);
    updatedFormData.append('gender', formData.gender);
    updatedFormData.append('breed', formData.breed);
    updatedFormData.append('weight', formData.weight);
    updatedFormData.append('color', formData.color);
    updatedFormData.append('alert_on', formData.alert_on ? 1 : 0); // Convert boolean to 1 or 0

    // Append arrays like behaviour, health, food, diseases to FormData (convert them to JSON strings)
    updatedFormData.append('behaviour', JSON.stringify(formData.behaviour));
    updatedFormData.append('health', JSON.stringify(formData.health));
    updatedFormData.append('diseases', JSON.stringify(formData.diseases));
    updatedFormData.append('food', JSON.stringify(formData.food));
    // Handle the 'image' field (ensure the file is attached)
    let image = document.getElementById('image');
    if (image.files.length > 0) {
      updatedFormData.append('image', image.files[0]);
    }

    // Handle 'owners' array - iterate and append each owner as a separate JSON string
    formData.owners.forEach((owner, index) => {
      updatedFormData.append(`owners[${index}][owner_name]`, owner.owner_name);
      updatedFormData.append(`owners[${index}][contact_no]`, owner.contact_no);
      updatedFormData.append(`owners[${index}][whatsapp_no]`, owner.whatsapp_no);
      updatedFormData.append(`owners[${index}][email]`, owner.email);
      updatedFormData.append(`owners[${index}][address]`, JSON.stringify(owner.address));
      updatedFormData.append(`owners[${index}][show_contact]`, owner.show_contact ? 1 : 0);  // Use 1 for true and 0 for false
      updatedFormData.append(`owners[${index}][show_whatsapp]`, owner.show_whatsapp ? 1 : 0);  // Same as above
      updatedFormData.append(`owners[${index}][show_email]`, owner.show_email ? 1 : 0);
    });

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL_NEW}/update-tag/${this.state.tag.tag_id}`, updatedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important to set this header when sending form data
        },
      });

      if (response.status === 200) {
        this.handleShowAlert();
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  render() {
    const { formData, petBreeds, states, suggestedBehaviours, suggestedFoods, suggestedHealths, suggestedDiseases, isPreviewVisible, showAlert } = this.state;
    const availableBreeds = petBreeds[formData.pet_type] || [];

    return (
      <div>
        <section className="collection section-b-space">
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              {showAlert && <CustomAlert path={"/pet-finder"} title={"Tag Updated Successfully"} message={"You will be redirected shortly..."} time={3000} onClose={this.handleHideAlert} />}
              <h3>Pet Details</h3>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="pet_name">Name:</label>
                  <input
                    type="text"
                    id="pet_name"
                    name="pet_name"
                    value={formData.pet_name}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="pet_type">Type:</label>
                  <select
                    id="pet_type"
                    name="pet_type"
                    value={formData.pet_type}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.pet_type !== "" ? <option value={formData.pet_type} selected>{formData.pet_type}</option>
                      : <option value="">Select</option>}
                    <option value="dog">Dog</option>
                    <option value="cat">Cat</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="gender">Gender:</label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.gender !== "" ? <option value={formData.gender} selected>{formData.gender}</option>
                      : <option value="">Select</option>}
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="prefer_not_to_say">Prefer not to say</option>
                  </select>
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="dob">Date-of-Birth:</label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    value={formData.dob}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="image">Image:</label>
                  {isPreviewVisible && formData.image && (
                    <div className="mt-3">
                      <img
                        src={
                          typeof formData.image === 'string' 
                            ? "https://animoxkart-users.s3.ap-south-1.amazonaws.com/pets/" + formData.image
                            : URL.createObjectURL(formData.image)  // If it's a File object, create an Object URL
                        }
                        alt="preview"
                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                      />
                    </div>
                  )}

                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" ref={this.fileInputRef} class="custom-file-input" name='image' id="image" onChange={this.handleFileChange} />
                      <label class="custom-file-label" for="image">Choose file</label>
                    </div>
                    {isPreviewVisible ? <div class="input-group-append">
                      <button class="input-group-text" id="" onClick={this.togglePreview}>Clear</button>
                    </div> : <></>}
                  </div>
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="breed">Breed:</label>
                  <select
                    id="breed"
                    name="breed"
                    value={formData.breed}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    <option value="">Select</option>
                    {availableBreeds.map((breed, index) => (
                      <option key={index} value={breed}>{breed}</option>
                    ))}

                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="weight">Weight:</label>
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    value={formData.weight}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="color">Colour: </label>
                  <input
                    type="text"
                    id="color"
                    name="color"
                    value={formData.color}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="behaviour">Behaviour:</label>
                  <div className="">
                    <div className="form-group" style={{ width: '100%' }}>
                      <div id="selectedBehavioursDiv" style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', minHeight: '40px' }}>


                      </div>
                      <label htmlFor=""> Suggestions:</label>
                      <div id="behaviour-suggestion-div">

                        {suggestedBehaviours.map((behaviour, index) => (
                          <button
                            key={index}
                            type="button"
                            id={`suggestion-${behaviour}`}
                            className="btn btn-sm"
                            onClick={() => this.handleBehaviourSelect(behaviour)}
                            style={{ display: 'inline-block', margin: '5px' }}
                          >
                            {behaviour}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="food">Food:</label>
                  <div className="">
                    <div className="form-group" style={{ width: '100%' }}>
                      <div id="selectedFoodDiv" style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', minHeight: '40px' }}>

                      </div>
                      <label htmlFor="">Suggestions:</label>
                      <div id="food-suggestion-div">

                        {suggestedFoods.map((food, index) => (
                          <button
                            key={index}
                            type="button"
                            id={`suggestion-${food}`}
                            className="btn btn-sm"
                            onClick={() => this.handleFoodSelect(food)}
                            style={{ display: 'inline-block', margin: '5px' }}
                          >
                            {food}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="health">Health:</label>
                  <div className="">
                    <div className="form-group" style={{ width: '100%' }}>
                      <div id="selectedHealthDiv" style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', minHeight: '40px' }}>
                        {/* Dynamically populated selected health items */}
                      </div>
                      <label htmlFor=""> Suggestions:</label>
                      <div id="health-suggestion-div">
                        {suggestedHealths.map((health, index) => (
                          <button
                            key={index}
                            type="button"
                            id={`suggestion-${health}`}
                            className="btn btn-sm"
                            onClick={() => this.handleHealthSelect(health)}
                            style={{ display: 'inline-block', margin: '5px' }}
                          >
                            {health}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="diseases">Diseases:</label>
                  <div className="">
                    <div className="form-group" style={{ width: '100%' }}>
                      <div id="selectedDiseasesDiv" style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', minHeight: '40px' }}>
                        {/* Dynamically populated selected diseases */}
                      </div>
                      <label htmlFor="suggestion" id="diseaseSuggestion"> Suggestions:</label>
                      <div id="disease-suggestion-div">
                        {formData.health.includes("Good Health") && formData.health.length == 1 ? "Your pet is healthy." : suggestedDiseases.map((disease, index) => (
                          <button
                            key={index}
                            type="button"
                            id={`suggestion-${disease}`}
                            className="btn btn-sm"
                            onClick={() => this.handleDiseaseSelect(disease)}
                            style={{ display: 'inline-block', margin: '5px' }}
                          >
                            {disease}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="pet_name">Pet Name:</label>
                  <input
                    type="text"
                    id="pet_name"
                    name="pet_name"
                    value={formData.pet_name}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="pet_type">Pet Type:</label>
                  <select
                    id="pet_type"
                    name="pet_type"
                    value={formData.pet_type}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.pet_type !== "" ? <option value={formData.pet_type} selected>{formData.pet_type}</option>
                      : <option value="">Select</option>}
                    <option value="dog">Dog</option>
                    <option value="cat">Cat</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="gender">Pet Gender:</label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.gender !== "" ? <option value={formData.gender} selected>{formData.gender}</option>
                      : <option value="">Select</option>}
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="dob">Pet Date-of-Birth:</label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    value={formData.dob}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
              </div> */}

              <h3>Guardian Details</h3>
              <span className='' style={{ "font-size": "12px", "color": "green", "lineHeight": "normal" }}>Uncheck if you would like to hide your contact when this tag is scanned during your pet lost.</span>
              {formData.owners.map((owner, index) => (
                <div key={index} className="row">
                  <div className="form-group col-md-3">
                    <label htmlFor={`owner_name_${index}`}>Name:</label>
                    <input
                      type="text"
                      id={`owner_name_${index}`}
                      name="owner_name"
                      value={owner.owner_name}
                      onChange={(e) => this.handleChange(e, index)}
                      required
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`contact_no_${index}`}>Contact no:</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input
                            type="checkbox"
                            id={`show_contact_${index}`}
                            name="show_contact"
                            checked={owner.show_contact}
                            onChange={(e) => this.handleChange(e, index)}
                            aria-label="Checkbox"
                          />
                        </div>
                      </div>
                      <input
                        type="number"
                        id={`contact_no_${index}`}
                        name="contact_no"
                        value={owner.contact_no}
                        onChange={(e) => this.handleChange(e, index)}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`whatsapp_no_${index}`}>Whatsapp no:</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input
                            type="checkbox"
                            id={`show_whatsapp_${index}`}
                            name="show_whatsapp"
                            checked={owner.show_whatsapp}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                        </div>
                      </div>
                      <input
                        type="number"
                        id={`whatsapp_no_${index}`}
                        name="whatsapp_no"
                        value={owner.whatsapp_no}
                        onChange={(e) => this.handleChange(e, index)}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`email_${index}`}>Email:</label>
                    <input
                      type="email"
                      id={`email_${index}`}
                      name="email"
                      value={owner.email}
                      onChange={(e) => this.handleChange(e, index)}
                      required
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`address_area_${index}`}>Address:</label>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input
                            type="checkbox"
                            id={`show_address_${index}`}
                            name="show_address"
                            checked={owner.address.show}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        id={`address_area_${index}`}
                        name="area"
                        value={owner.address.area}
                        onChange={(e) => this.handleChange(e, index)}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`address_city_${index}`}>City:</label>
                    <input
                      type="text"
                      id={`address_city_${index}`}
                      name="city"
                      value={owner.address.city}
                      onChange={(e) => this.handleChange(e, index)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`address_state_${index}`}>State:</label>
                    <select
                      id={`address_state_${index}`}
                      name="state"
                      value={owner.address.state}
                      onChange={(e) => this.handleChange(e, index)}
                      className="form-control"
                    >
                      <option value="">Select State</option>
                      {states.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`address_country_${index}`}>Country:</label>
                    <select
                      id={`address_country_${index}`}
                      name="country"
                      value={owner.address.country}
                      onChange={(e) => this.handleChange(e, index)}
                      className="form-control"
                    >
                      <option value="India">India</option>
                    </select>
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor={`address_pincode_${index}`}>Pincode:</label>
                    <input
                      type="text"
                      id={`address_pincode_${index}`}
                      name="pincode"
                      value={owner.address.pincode}
                      onChange={(e) => this.handleChange(e, index)}
                      className="form-control"
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <button type="button" onClick={() => this.removeOwner(index)} className="btn btn-danger">Remove</button>
                  </div>

                </div>
              ))}

              <button type="button" onClick={this.addOwner} className="btn btn-sm btn-outline">Add another Guardian</button>


              <h5 className='mt-5'>Security Settings</h5>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="alert_on">Turn on Lost Alert:</label>
                  <input
                    className="m-2"
                    type="checkbox"
                    id="alert_on"
                    name="alert_on"
                    checked={formData.alert_on}
                    onChange={this.handleChange}
                  />
                  <p>Check if your pet is lost; this will activate the alert system. You can update later if needed.</p>
                </div>
              </div>

              <button type="submit" className="m-2 btn btn-outline">Submit</button>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default UpdatePetFinder;
